import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00baff',
      main: '#00b0f0',
      dark: '#2839ab'
    },
    secondary: {
      light: '#c8079d',
      main: '#a100ff',
      dark: '#460073',
    },
    grey: {
      light: '#e0e0e0',
      main: '#707070',
      dark: '#5a5a5a'
    },
    default:{
      light: '#f5f5f5',
      main: 'white'
    },
    error: {
      main: red.A400,
    },
  },
  overrides:{
    MuiButton:{
      root:{
        textTransform: 'unset',
        boxShadow: 'unset',
        borderRadius: 0, 
        fontWeight: 'bold'
      },
      containedPrimary:{
        color: 'white'
      },
      containedSecondary:{
        color: 'white'
      }
    },
    MuiSlider:{
      root:{
        height: 12,
        width: 'calc(100% - 90px)',
        marginRight: 10
      },
      rail: {
        height: 12,
        borderRadius: 12,
        color: '#E0E0E0'
      },
      track: {
        height: 12,
        borderRadius: 12,
        background: 'transparent linear-gradient(270deg, #2839AB 0%, #00B0F0 100%) 0% 0% no-repeat padding-box'
      },
      thumb:{
        height: 25,
        width: 25,
        background: 'transparent linear-gradient(270deg, #00B0F0 0%, #2839AB 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029'
      },
      valueLabel:{
        color:'transparent',
        top:34,
        '& span': {
          fontFamily: '"Graphik Black", "Arial Black", sans-serif !important',
          fontSize:'1.0rem'
        }
      }
    },
    MuiGrid:{
      container:{
        // alignItems: 'center'
      }
    },
    MuiLinearProgress:{
      colorPrimary:{
        background: 'transparent linear-gradient(240deg, #c8079d 0%, #a100ff 100%) 0% 0% no-repeat padding-box',
      },
      barColorPrimary:{
        background: 'transparent linear-gradient(-90deg, #2839ab 0%, #00b0f0 100%) 0% 0% no-repeat padding-box'
      }
    },
    MuiTableCell:{
      root:{
        padding: '8px 0',
        fontSize: 16,
        fontFamily: '"Graphik Regular", "Arial", sans-serif !important',
      },
      body:{
        color: 'black',
        fontWeight: 'bold'
      },
      head: {
        color: '#707070',
      }
    },
    MuiTableRow:{
      root:{
        borderBottom: '2px solid #E0E0E0'
      },
      head:{
        borderBottom: '4px solid #E0E0E0'
      }
    },
    MuiTableContainer:{
      root:{
        boxShadow: 'none',
      }
    },
    MuiFormLabel:{
      root:{
        color: 'black',
        fontFamily: '"Graphik Regular", "Arial", sans-serif !important',
      }
    },
    MuiListItem:{
      root:{
        "&$selected":{
          borderRight: '3px solid #6B22B9',
          backgroundColor: '#F5F5F5',
          color: '#6B22B9'
        }
      }
    },
    MuiDialog:{
      paper:{
        borderRadius: 'unset',
        boxShadow: 'unset'
      },
      paperWidthSm:{
        width: '90%',
        maxWidth: '800px'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor:'rgba(0, 0, 0, 0.75)',
      }
    },
    MuiFormHelperText:{
      contained:{
        marginLeft: 0
      }
    },
    MuiList:{
      root:{
        maxWidth: 824
      }
    },
    MuiMenuItem: {
      root:{
        maxWidth: 824
      }
    }
  }
});

export default theme;

