import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, Button } from '@material-ui/core';
import './static/global.css';
import theme from './data/theme';
import Loading from './components/shared/Loading';

const useStyles = makeStyles(theme => ({
  app: {
    height: '100vh',
    display: 'flex'
  },
  loginContainer: {
    maxWidth: 700,
    margin: 'auto',
    padding: '20px 40px',
    backgroundColor: 'white'
  },
  button: {
    margin: '20px 0'
  }
}));

const App = () => {
  const classes = useStyles();

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // only show login form if user is not logged in
    axios.get('api/auth')
      .then(() => {
        window.location.href = '/menu';
      })
      .catch(err => {
        if (err.response.status !== 401) console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleUser = event => {
    setUser(event.target.value);
  };

  const handlePassword = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (!user || !password) return;

    axios.post('api/login', { user, password })
      .then(() => {
        window.location.href = '/menu';
      })
      .catch(err => {
        const status = err.response.status;
        if (status === 401) {
          alert('Unauthorized: invalid login');
        } else if (status === 500) {
          alert('Server error: please try again later');
        }
      });
  };

  return isLoading ?
    <Loading /> :
    (
      <ThemeProvider theme={theme}>
        <Container className={`${classes.app} electric-gradient`} maxWidth={false}>
          <Grid container className={classes.loginContainer}>
            <h1>Login</h1>
            <Grid item xs={12}>
              <TextField 
                label='Email'
                variant='outlined'
                margin='dense'
                name="user"
                fullWidth
                className={classes.loginField}
                value={user}
                onChange={handleUser}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                label='Password'
                type="password"
                variant='outlined'
                margin='dense'
                name="password"
                fullWidth
                className={classes.loginField}
                value={password}
                onChange={handlePassword}
              />
            </Grid>
            <Grid item xs={4}>
              <Button 
                color='secondary' 
                variant='contained'
                className={classes.button}
                disabled={!user || !password}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
};

export default App;