import React from 'react';
import { Container, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    width: 100
  }
}));

const Loading = props => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth={false}>
      <CircularProgress
        color="primary"
        variant="indeterminate"
      />
    </Container>
  );
};

export default Loading;